$backgroundColor: #1f2325;
$textColor: #848485;

.timeline-container {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 40px 0;
    width: 100%;
    color: $textColor;
    &::after {
        background-color: #4e4e4e;
        content: '';
        position: absolute;
        left: calc(50% - 2px);
        width: 4px;
        height: 100%;
        @media only screen and (max-width: 767px) {
            left: 0;
        }
    }
}

.timeline-item {
    display: flex;
    justify-content: flex-end;
    padding-right: 30px;
    position: relative;
    margin: 10px 0;
    width: 50%;
    perspective: 1000px;
    &::hover {
        -webkit-transform: rotateY(180deg);
        -moz-transform: rotateY(180deg);
        transform: rotateY(180deg);
      }
    &:nth-child(odd) {
        align-self: flex-end;
        justify-content: flex-start;
        padding-left: 30px;
        padding-right: 0;
    }
    @media only screen and (max-width: 767px) {
        width: 100%;
        align-self: flex-end;
        justify-content: flex-start;
        padding-left: 30px;
        padding-right: 0;
    }
}

.timeline-item-content {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    background-color: $backgroundColor;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 15px;
    position: relative;
    width: 400px;
    max-width: 70%;
    text-align: right;
    transition: all 0.6s ease;
    transform-style: preserve-3d;
    transition: transform 1s;
    &::after {
        content: ' ';
        background-color: $backgroundColor;
        box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.2);
        position: absolute;
        right: -7.5px;
        top: calc(50% - 7.5px);
        transform: rotate(45deg);
        width: 15px;
        height: 15px;
        @media only screen and (max-width: 767px) {
            right: auto;
            left: -7.5px;
            box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
        }
    }
    @media only screen and (max-width: 1023px) {
        max-width: 100%;
    }
    .tag {
        color: $backgroundColor;
        font-size: 12px;
        font-weight: bold;
        top: 5px;
        left: 5px;
        letter-spacing: 1px;
        padding: 5px;
        position: absolute;
        text-transform: uppercase;
    }
    time {
        color: #777;
        font-size: 12px;
        font-weight: bold;
    }
    a {
        color: #fff;
        margin: 1em 0;
        text-decoration: none;
        font-size: 14px;
        font-weight: bold;
        &::after {
            content: ' ►';
            font-size: 12px;
        }
    }
    .circle {
        background-color: #4e4e4e;
        border: 3px solid #4e4e4e;
        border-radius: 50%;
        position: absolute;
        top: calc(50% - 10px);
        right: -40px;
        width: 20px;
        height: 20px;
        z-index: 100;
        @media only screen and (max-width: 767px) {
            right: auto;
            left: -40px;  
        }
    }
}

.timeline-item:nth-child(odd) .timeline-item-content {
    text-align: left;
    align-items: flex-start;
    &::after {
        right: auto;
        left: -7.5px;
        box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
    }
    .tag {
        left: auto;
        right: 5px;
    }
    .circle {
        right: auto;
        left: -40px;
    }
}

@media only screen and (max-width: 767px) {
    .timeline-item-content,
    .timeline-item:nth-child(odd) .timeline-item-content {
        padding: 15px 10px;
        text-align: center;
        align-items: center;
    }

    .timeline-item-content {
        .tag {
            width: calc(100% - 10px);
            text-align: center;
        }
        time {
            margin-top: 20px;
        }
        a {
            text-decoration: underline;
            &::after {
                display: none;
            }
        }
    }
}

.flip-container {
	perspective: 1000px;
    width: 100%;
}

.preserve-card {
    transition: 0.6s;
	transform-style: preserve-3d;
	position: relative;
    width: 100%;
    height: 150px;
    &.flipped {
        transform: rotateY(180deg);
    }
    .content {
        backface-visibility: hidden;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        &.front {
            z-index: 2;
            text-align: center;
            img {
                opacity: 0.5;
                width: 120px;
                margin-top: 10px;
            }
        }
        &.back {
            transform: rotateY(180deg);
        }
    }
}