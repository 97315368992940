.animated {
  transition: all .3s;

  &.fade {
    opacity: 0;
    transition: all 0s;

    &.fade-in-out {
      opacity: 1;
      transition: all .5s;
    }
  }

  &.animated-hover {
    &:hover {
      transition: all .3s;
    }
  }

  &.animated-focus {
    &:focus {
      transition: all .3s;
    }
  }
}
