/* Font Family */
* {
  font-family: 'Source Sans Pro', 'Noto Sans TC', -apple-system,
    BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

/* Font Styling */
.break-all {
  word-break: break-all;
}

.skip-word {
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 0;
}

.delete-line {
  text-decoration:line-through;
}

/* Font Size */
$fonts: (
  header: 48px,
  title: 36px,
  body: 30px,
  desc: 24px,
  small: 20px,
  icon: 14px
);

@each $name, $font in $fonts {
  .small {
    .#{$name}-text {
      font-size: calc(#{$font} * 0.9) !important;

      * {
        font-size: calc(#{$font} * 0.9) !important;
      }
    }
  }

  .#{$name}-text {
    font-size: $font !important;

    * {
      font-size: $font !important;
    }
  }
}

/* Vertical Alignment */
$v-aligns: (top, center, bottom);
@each $v-align in $v-aligns {
  .vertical-#{$v-align} {
    vertical-align: #{$v-align} !important;
  }
}

/* Font Weight */
$weights: (bold, bolder, normal, light, lighter);
@each $weight in $weights {
  .#{$weight} {
    font-weight: #{$weight} !important;
  }
}

/* Font Align */
$aligns: (left, right, center, justify);
@each $align in $aligns {
  .text-#{$align} {
    text-align: #{$align} !important;

    input {
      text-align: #{$align} !important;
    }
  }
}
