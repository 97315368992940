/* Image Size */
.img-responsive { max-width: 100%; }

/* Image Transform */
$degs: (90, 180, 270);
@each $deg in $degs {
  .deg-#{$deg} {
    -webkit-transform: rotate(#{$deg}deg);
    transform: rotate(#{$deg}deg);
  }
}
