/* Display Methods */
.hidden {
  opacity: 0;
}

$displays: (block, inline-block, table, table-cell, none);
@each $display in $displays {
  .#{$display} {
    display: #{$display} !important;
  }
}

/* Position */
.pull-left { float: left !important; }
.pull-right { float: right !important; }

$postions: (relative, absolute, fixed, sticky);
@each $postion in $postions {
  .#{$postion} {
    position: #{$postion} !important;
  }
}

/* Main Container */
.container {
  height: 100vh;
  background-color: map-get($colors, blank);

  .scroll-view {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    white-space: nowrap;
  }

  &.no-scroll {
    overflow: hidden;
  }
}

/* Grid System */
.flex-container {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;

  &.space {
    > [class*="flex-"] {
      margin: 0px 10px;

      &:first-child {
        margin-left: 0px;
      }

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  &.col {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;

    &.space {
      > div[class^="flex-"] {
        margin: 5px 0px;

        &:first-child {
          margin-top: 0px;
        }

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
}

.flex-center {
  align-self: center;
  align-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.flex-start {
  align-self: flex-start;
}

.flex-end {
  align-self: flex-end;
}

.flex-auto {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

@for $i from 1 through 12 {
  .flex-#{$i} {
    -webkit-box-flex: $i;
    flex: $i;
  }
}
