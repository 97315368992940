/* Common Colors */
$colors: (
  theme: #2ddbbb,
  black: #000000,
  blank: #ffffff
);

/* Filter */
.grey-filter {
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
}

@each $name, $color in $colors {
  .#{$name}-color {
    color: $color !important;
  }

  .#{$name}-background {
    background-color: $color !important;
  }

  .#{$name}-border {
    border-color: $color !important;
  }
}

@function alphaColor($c, $a) {
  $result: change-color(map-get($colors, $c), $alpha: $a);
  @return $result;
}

@function alphaRealColor($b, $c, $a) {
  $baseColor: alphaColor($c, $a);
  $backgroundColor: map-get($colors, $b);
  @return mix(rgb(red($baseColor), green($baseColor), blue($baseColor)), $backgroundColor, alpha($baseColor) * 100%)
}
